import { Inject, Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoginService } from 'ui-shared';

@Injectable({
  providedIn: 'root'
})
export class GerenciadorConsultaGuard implements CanActivate {
  constructor(private router: Router,
    @Inject(LoginService) private loginService: LoginService
  ) { }

  canActivate() {
    if (!this.loginService.estaLogado()) {
      this.router.navigate(['/login']);
      return false;
    }
    else if (!this.loginService.suporte) {
      return this.router.navigate(['acesso-negado']);
    }
    return true;
  }
}
