import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
    API_URL,
    ErrorHttpInterceptor, FormModule, GridModule,
    IDENTIDADE_API_URL,
    JWTInterceptor,
    LoaderInterceptor, MASTER_DEFAULT_OPTIONS,
    MasterModule, matFormField,
    matSnackbar
} from 'ui-shared';
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { environment } from "../environments/environment";
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from "@angular/material/snack-bar";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from "@angular/material/form-field";
import {AcessoNegadoComponent} from "./acesso-negado/acesso-negado.component";
import {NgxsStoreModule} from "./store/store.module";
import {LoginComponent} from "./login/login.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatDialogModule} from "@angular/material/dialog";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {master} from "./master-config";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
registerLocaleData(ptBr);

@NgModule({
    declarations: [
        AppComponent,
        AcessoNegadoComponent,
        LoginComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgxsStoreModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatDialogModule,
        MatSnackBarModule,
        MatAutocompleteModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatIconModule,
        MasterModule,
        FormModule,
        GridModule,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'pt' },
        { provide: MASTER_DEFAULT_OPTIONS, useValue: master },
        { provide: API_URL, useValue: environment.gerenciadorConsulta.apiUrl, multi: true },
        { provide: IDENTIDADE_API_URL, useValue: environment.identidade.apiUrl, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JWTInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorHttpInterceptor, multi: true },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: matSnackbar() },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: matFormField() }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
